import React, { useState } from "react";  // Import useState
import { useSelector, useDispatch } from "react-redux";
import { emptyCart, sendCartToAPI } from "../features/cart";
import ItemActions from "./ProductsList/ItemActions";

export default function Cart() {
  const cart = useSelector((state) => state.cart);
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);

  const [formData, setFormData] = useState({
    userEmail:    "lanoix.pascal@gmail.com",
    firstName:    "Pascal",
    lastName:     "LANOIX",
    phoneNumber:  "0631642670",
    otherDetails: "",
  });

  const handleOrderSubmit = async () => {
    dispatch(sendCartToAPI(formData.userEmail, formData.firstName, formData.lastName, formData.phoneNumber, formData.otherDetails));
  };
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };


  return (
    <div>
      <h2 className="is-size-2 is-grey mb-3">Votre panier</h2>
      <div className="table-container" id="cart-items">
        <table className="table is-bordered is-striped is-narrow is-hoverable is-fullwidth">
          <tbody>
            {cart.cartItems.length > 0 ? (
              cart.cartItems.map((item) => (
                <tr key={item.id}>
                  <td>
                    <span>{item.description}</span>
                  </td>
                  <td>
                    <ItemActions item={item} />
                  </td>
                </tr>
              ))
            ) : (
              <p>Votre panier est vide.</p>
            )}
          </tbody>
        </table>
      </div>

      <div className="block" id="cart-total-price">
        Total de pièces :{" "}
        {cart.cartItems.reduce((acc, curr) => acc + curr.quantite, 0)}
      </div>

      <div className="block">
        <form onSubmit={handleOrderSubmit}> 
          <div className="field">
            <label className="label" htmlFor="userEmail">Email</label>
            <div className="control">
              <input
                type="email"
                id="userEmail"
                name="userEmail"
                value={formData.userEmail}
                onChange={handleInputChange}
                placeholder="votre.email@exemple.com"
                className="input"
                required
              />
            </div>
          </div>

          <div className="field">
            <label className="label" htmlFor="firstName">Prénom</label>
            <div className="control">
              <input
                type="text"
                id="firstName"
                name="firstName"
                value={formData.firstName}
                onChange={handleInputChange}
                placeholder="Votre prénom"
                className="input"
                required
              />
            </div>
          </div>

          <div className="field">
            <label className="label" htmlFor="lastName">Nom</label>
            <div className="control">
              <input
                type="text"
                id="lastName"
                name="lastName"
                value={formData.lastName}
                onChange={handleInputChange}
                placeholder="Votre nom"
                className="input"
                required
              />
            </div>
          </div>

          <div className="field">
            <label className="label" htmlFor="phoneNumber">Numéro de téléphone</label>
            <div className="control">
              <input
                type="tel"
                id="phoneNumber"
                name="phoneNumber"
                value={formData.phoneNumber}
                onChange={handleInputChange}
                placeholder="0601020304"
                className="input"
                required
              />
            </div>
          </div>

          <div className="field">
            <label className="label">Autres détails</label>
            <div className="control">
              <textarea
                id="otherDetails"
                name="otherDetails"
                value={formData.otherDetails}
                onChange={handleInputChange}
                className="textarea"
                placeholder="Autres détails"></textarea>
            </div>
          </div>

          <div className="buttons">

            <button type="submit" className="button is-success" disabled={cart.cartItems.length === 0}>
              Valider votre commande
            </button>
            <button
              className="button is-danger"
              onClick={() => dispatch(emptyCart())}
              disabled={cart.cartItems.length === 0}
            >
              Vider le panier
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

