import { createSlice } from "@reduxjs/toolkit";
import { PRODUCT_ORDERS_API_URL } from "../config.js";

const initialState = {
  cartItems: []
};

export const cart = createSlice({
  name: "cart",
  initialState,
  reducers: {
    createCartItem: (state, action) => {
      state.cartItems.push(action.payload);
    },
    updateItemFromSelect: (state, action) => {
      state.cartItems.find((el) => el.id === action.payload.id).quantite =
        Number(action.payload.value);
    },
    removeFromCartItem: (state, action) => {
      state.cartItems = state.cartItems.filter(
        (item) => item.id !== action.payload.id
      );
    },
    deleteFromCart: (state, action) => {
      const indexOfItemToRemove = state.cartItems.findIndex(
        (el) => el.id === action.payload
      );
      state.cartItems.splice(indexOfItemToRemove, 1);
    },
    plusOne: (state, action) => {
      const itemToUpdate = state.cartItems.find(
        (item) => item.id === action.payload
      );
      if (itemToUpdate) {
        itemToUpdate.quantite += 1;
      }
    },
    removeOne: (state, action) => {
      const itemToUpdate = state.cartItems.find(
        (item) => item.id === action.payload
      );
      if (itemToUpdate && itemToUpdate.quantite > 1) {
        itemToUpdate.quantite -= 1;
      }
    },
    emptyCart: (state) => {
      state.cartItems = [];
    },
  },
});

export function addOneToCart(action) {
  return function (dispatch, getState) {
    const storeState = getState();

    const isAlreadyPresent = storeState.cart.cartItems.find(el => el.id === action);

    if(!isAlreadyPresent){
      const itemToAdd = storeState.product.productComponents.find(
        (el) => el.component.id === action
      );

      const newCartItem = {
        id: itemToAdd.component.id,
        name: itemToAdd.component.name,
        label: itemToAdd.component.label,
        description: itemToAdd.component.description,
        designation_repere: itemToAdd.component.landmark,
        fabricant: itemToAdd.component.maker,
        reference_fabricant: itemToAdd.component.maker_reference,
        fournisseur: itemToAdd.component.supplier,
        reference_fournisseur: itemToAdd.component.supplier_reference,
        quantite: itemToAdd.component.quantity,
        picked: true,
        category: itemToAdd.component.category,
        quantityMin: itemToAdd.quantity_min,
        quantityMax: itemToAdd.quantity_max,
      };

      dispatch(createCartItem(newCartItem));
    }
  };
}

export function sendCartToAPI(userEmail, firstName, lastName, phoneNumber, otherDetails) {
  return async function (dispatch, getState) { // Make the thunk async
    const state = getState();
    const cartItems = state.cart.cartItems;
    const productId = state.product.id; // Get the product ID

    const products_components = {}; // Initialize as an object

    cartItems.forEach(item => {
      if (!products_components[productId]) {
        products_components[productId] = {}; // Initialize an empty object for the product
      }
      products_components[productId][item.id] = item.quantite;
    });


    const orderData = {
      product_order: {
        user_email: userEmail,
        first_name: firstName,
        last_name: lastName,
        phone_number: phoneNumber,
        ...otherDetails,
        products_components: products_components // Use the correctly structured object
      }
    };

    try {
      const response = await fetch(`${PRODUCT_ORDERS_API_URL}`, { // Use await
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(orderData)
      });

      if (!response.ok) {
        throw new Error(`API request failed with status ${response.status}`);
      }

      const responseData = await response.json(); // Get JSON response
      console.log("Order sent successfully:", responseData);
      dispatch(emptyCart());

    } catch (error) {
      console.error("Error sending order to API:", error);
    }
  };
}


export const { createCartItem, removeFromCartItem, updateItemFromSelect, deleteFromCart,
  plusOne,
  removeOne,
  emptyCart, } = cart.actions;
export default cart.reducer;